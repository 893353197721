
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {CLAIM_DECISION} from "@/core/config/Constant";
import Froala from "@/components/base/editor/Froala.vue";
import ClaimService from "@/core/services/ClaimService";

export default defineComponent({
  name: "ClaimDecisionForm",
  components: {Froala, BaseRadio, BaseForm},
  props: {
    claim: {},
    claimId: {},
  },
  emits: ['saved'],
  setup(props) {
    const submitting = ref(false);
    const model = ref(props.claim)
    watch(() => props.claim, cb => {
      model.value = cb;
    })
    return {
      submitting,
      model,
      CLAIM_DECISION,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true
      ClaimService.updateDecision(this.claimId, this.model).then(res => {
        this.$emit('saved', res)
      }).finally(() => {
        this.submitting = false;
      })
    },
  }
})
