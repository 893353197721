
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {CLAIM_DOC_STATUS, REMINDER_STATUS} from "@/core/config/Constant";
import ClaimService from "@/core/services/ClaimService";
import DatePicker from "@/components/base/datetime/DatePicker.vue";

export default defineComponent({
  name: "ClaimDocumentStatusForm",
  components: {DatePicker,  BaseSelect, BaseForm},
  props: {
    claimId: {},
    claim: {type: Object}
  },
  emits: ['saved'],
  setup(props) {
    const x = ref('');
    const submitting = ref(false);
    const model = ref(props.claim)
    watch(() => props.claim, cb => {
      model.value = cb;
    })
    return {
      CLAIM_DOC_STATUS,
      REMINDER_STATUS,
      submitting,
      model,
      x,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      ClaimService.updateDoc(this.claimId, this.model).then(res => {
        this.$emit('saved', res);
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
