
import {computed, defineComponent} from "vue";
import store from "@/store";
import Row from "@/components/base/common/Row.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import ReminderStatus from "@/views/claim/adjudication/ReminderStatus.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import ClaimDecisionStatus from "@/views/claim/adjudication/ClaimDecisionStatus.vue";
import ClaimApprovalStatus from "@/views/claim/adjudication/ClaimApprovalStatus.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ClaimDocumentStatusForm from "@/views/claim/adjudication/ClaimDocumentStatusForm.vue";
import {Actions} from "@/store/enums/StoreEnums";
import ClaimDecisionForm from "@/views/claim/adjudication/ClaimDecisionForm.vue";
import ClaimApprovalForm from "@/views/claim/adjudication/ClaimApprovalForm.vue";

export default defineComponent({
  name: "Summary",
  components: {
    ClaimApprovalForm,
    ClaimDecisionForm,
    ClaimDocumentStatusForm,
    BaseModal,
    ClaimApprovalStatus,
    ClaimDecisionStatus, QuickAction, ReminderStatus, DateTimeFormat, Row,
  },
  setup() {
    const claim = computed(() => store.state.ClaimModule.claim);
    return {
      claim,
    }
  },
  methods: {

    onUpdateDecision() {
      const modal = this.$refs.claimDecisionIdRef as typeof BaseModal;
      modal.showBaseModal();
    },

    onUpdateDocStatus() {
      const modal = this.$refs.claimDocumentsStatus as typeof BaseModal;
      modal.showBaseModal();
    },

    onUpdateApproval() {
      const modal = this.$refs.claimApprovalStatusRef as typeof BaseModal;
      modal.showBaseModal();
    },
    decisionSaved() {
      store.dispatch(Actions.LOAD_CLAIM, this.claim.id)
      const modal = this.$refs.claimDecisionIdRef as typeof BaseModal;
      modal.hideBaseModal();
    },

    approvalSaved() {
      store.dispatch(Actions.LOAD_CLAIM, this.claim.id)
      const modal = this.$refs.claimApprovalStatusRef as typeof BaseModal
      modal.hideBaseModal()
    },

    docSaved() {
      store.dispatch(Actions.LOAD_CLAIM, this.claim.id)
      const modal = this.$refs.claimDocumentsStatus as typeof BaseModal;
      modal.hideBaseModal();
    }
  }
})
