
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "ReminderStatus",
  props: {
    status: {}
  },
  setup(props) {
    const data = ref({clazz: '', name: ''})
    const buildStatus = (status) => {

      switch (status) {
        case 'NOT_SET':
          data.value = {clazz: 'dark', name: 'Not set'}
          break
        case 'COMPLETED':
          data.value = {clazz: 'success', name: 'Completed'}
          break
      }
    }
    watch(() => props.status, (cb) => {
      buildStatus(cb)
    })
    buildStatus(props.status)
    return {
      data
    }
  }
})
