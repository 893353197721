
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "ClaimApprovalStatus",
  props: {status: {}},
  setup(props) {
    const data = ref({clazz: '', name: ''})
    const buildStatus = (status) => {
      switch (status) {
        case 'NOT_REQUIRED':
          data.value = {clazz: 'dark', name: 'Not Required'}
          break
        case 'REQUESTED':
          data.value = {clazz: 'primary', name: 'Requested'}
          break
        case 'APPROVED':
          data.value = {clazz: 'success', name: 'Approved'}
          break
        case 'NOT_APPROVED':
          data.value = {clazz: 'warning', name: 'Not Approved'}
          break
      }
    }
    watch(() => props.status, (cb) => {
      buildStatus(cb)
    })
    buildStatus(props.status)
    return {
      data
    }
  }
})
